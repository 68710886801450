@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Tapestry&display=swap');

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

body {
  margin: 0;
  /* background-color: hsl(218, 41%, 15%); */
  font-family: 'Josefin Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Josefin Sans', sans-serif;;
}

.bg-glass {
  background-color: hsla(0, 0%, 100%, 0.9) !important;
  backdrop-filter: saturate(200%) blur(25px);
}

.services:hover {
  text-decoration: underline;
}

.service-heading {
  color: blue;
  transition: transform .2s; 
}

.service-heading:hover {
  transform: scale(1.5);
  text-decoration: underline;
}

.card  {
  overflow: hidden;
}

.contactcol {
  text-align: center;
}

.icon {
  font-size: 45px;
}
.contactfield {
  text-decoration:solid;
  font-weight: bold;
  color: black;
  font-size: 30px;
}
.contactvalue {
  margin-top: 5px;  
}
.contactvalue:hover {
  transform: scale(1.2);
}
.contact-us-title {
  font-weight: bold;
  text-decoration: underline;
  color: blue;
}
.contact-heading{
  text-decoration: solid;
  font-size: 30px;
  font-weight: bold;
  text-shadow: 2px
}
.contact-form-element {
  margin-top: 20px;
  margin-bottom : 20px;
}
.contact-form-select {
 font-style: normal; 
}

.contact-form-label {
  margin-bottom: 5px;
  margin-left: 5px;
}

.contact-form-label-mandatory:after {
  content:" *";
  color: red;
}

.footer-anchor:hover {
  text-decoration: underline;
}

/* .servicesCard {
  transition: 0.5s all ease-in-out;
}

.servicesCard:hover {
  transform: scale(1.05);
} */
